import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";
import CommunicateIcon from '../../icons/communicate.svg';
import MarketResearchIcon from '../../icons/market-research.svg';

import ConnectMiVideo from '../../media/Connect-Mi-TV-Flow.mp4';


const PortfolioHeader = () => (
  <section className="bg-dark py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">Connect Mi</p>
              <p className="text-white text-muted small">Connect Mi is a fake company name to protect our client’s
                identity.</p>
            </div>
            <div className="col-12 mt-4 mt-lg-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">Backend Development
                <br></br>
                Frontend Development
                <br></br>
                UI/UX Services
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mt-5 mt-lg-0">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white mb-0">
            we helped connect mi push shopify’s limitations and built a store offering customers products with multiple
            options, scheduled installations, and service subscriptions all in one place.
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const PortfolioVideo = () => (
  <section className="bg-light pt-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <video controls style={{maxWidth: '100%'}}>
            <source type="video/mp4" src={ConnectMiVideo}/>
          </video>
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section>
    <div className="container py-6">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>As flexible and powerful Shopify is, it did not fully meet the project requirements this customer had.</p>
          <p>Connect Mi needed a solution which allowed them to offer customers a way to buy products, schedule
            installation dates, and subscribe to service subscriptions. We built integrations between Connect Mi’s
            Shopify account and Recurly’s API.</p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>project goals</h2>
          <ol className="text-primary m-0 pl-3">
            <li><span className="text-dark">Review the provided mockups for a streamlined user experience</span></li>
            <li><span className="text-dark">Provide a seamless checkout process that accounts for service and product sales.</span>
            </li>
            <li><span className="text-dark">Integrate with pre-existing tools to minimize workflow impact.</span></li>
            <li><span className="text-dark">Stretch out of the box capabilities to meet client requirements.</span></li>
            <li><span className="text-dark">Steamroll a tight deadline with a large, coordinated team effort.</span>
            </li>
            <li><span className="text-dark">Provide training and document for client owned future maintenance.</span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);


const DevelopmentLanguagesAndNeeds = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Software Used</h2>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/sketch.svg"
                        alt="Sketch"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/invision.png"
                        alt="Invision"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/vscode.svg"
                        alt="Visual Studio Code"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/github.svg"
                        alt="GitHub"
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">development languages used</h2>
              <p className="mb-0">HTML</p>
              <p className="mb-0">JSX</p>
              <p className="mb-0">CSS</p>
              <p className="mb-0">JavaScript</p>
              <p className="mb-0">Liquid</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">
                backend frameworks & libraries used
              </h2>
              <p className="mb-0">Node.js</p>
              <p className="mb-0">Express</p>
              <p className="mb-0">Bull (Redis)</p>
              <p className="mb-0">Recurly.js</p>
              <p className="mb-0">axios</p>
              <p className="mb-0">eslint</p>
              <p className="mb-0">jsonp</p>
              <p className="mb-0">jest</p>
              <p className="mb-0">shopify-api-node</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">
                frontend libraries & frameworks used
              </h2>
              <p className="mb-0">React</p>
              <p className="mb-0">Bootstrap</p>
              <p className="mb-0">Mustache</p>
              <p className="mb-0">Webpack</p>
              <p className="mb-0">jQuery</p>
              <p className="mb-0">s-cookie</p>
              <p className="mb-0">lodash</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2 mt-5 mt-lg-0">Research Methods</h2>
              <p className="d-flex align-items-center">
                <CommunicateIcon width={55} height={55} className="text-muted"/>
                <span className="pl-4"> Discovery Workshops</span>
              </p>
              <p className="d-flex align-items-center">
                <span className="p-n-1 border border-dark">
                <CommunicateIcon width={55} height={55} className="text-muted"/>
                </span>
                <span className="pl-4">Internal Business Analysis</span>
              </p>
              <p className="d-flex align-items-center">
                <MarketResearchIcon width={55} height={55} className="text-muted"/>
                <span className="pl-4">Internal Stakeholder Interviews</span>
              </p>
            </div>

            <div className="col-12">
              <h2 className="mb-2 mt-5">Tools Used</h2>
              <p className="mb-0">AWS Fargate</p>
              <p className="mb-0">AWS S3/Cloudfront</p>
              <p className="mb-0">Shopify</p>
              <p className="mb-0">Bold Product Options</p>
              <p className="mb-0">New Relic</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location}/>
        </div>
      </div>
    </div>
  </section>
);



const ProductDetailImage = () => (
  <section>
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <StaticImage
            src="../../images/portfolio/connect-mi/connect-mi-landing-product-detail.jpeg"
            alt="Product Detail"
          />
        </div>
      </div>
    </div>
  </section>

)

const ConnectMiTVFlow = () => (
  <section>
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <StaticImage
            src="../../images/portfolio/connect-mi/connect-mi-tv-flow.jpeg"
            alt="TV Flow"
          />
        </div>
      </div>
    </div>
  </section>
)

const KeyFocusAreas = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">

        <div className="col-12 col-lg-6">
          <h2 className="pb-3 m-0">key focus areas</h2>
          <p>How can we use customer pain points to streamline our approach?</p>
          <p> How do we provide an intuitive checkout experience?</p>
          <p>How can we leverage and maximize existing tools for an efficient workflow?</p>
          <p>What are the best methods to push Shopify’s limits with deep customizations?</p>
          <p>On a very strict deadline, how do we unify multiple teams unique workflows to minimize duplicate
            efforts?</p>
          <p>How can 729 provide the most comprehensive training and documentation for future client-owned
            maintenance?</p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2 className="mb-2">core client needs</h2>
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <p><strong>Very tight turn around</strong><br></br>Connect Mi needed fully functioning features in front
                of
                their
                customers in record time.</p>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <p><strong>Technology Requirements</strong><br></br>Our client was bound to Shopify and Recurly, so they
                needed a
                custom solution that leveraged both in
                the most efficient way.</p>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <p><strong>Stakeholder alignment</strong><br></br>All teams and stakeholders needed to be moving in unison
                to
                meet
                the tight deadline.</p>
            </div>
            <div className="col-12 col-lg-6">
              <p><strong>UI/UX and Design Consulting</strong><br></br>This client had a good start to the design
                process,
                but
                needed consulting to bring all the pieces together and across the finish line. </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
);

const ConnectMiAccount = () => (
  <section>
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <StaticImage
            src="../../images/portfolio/connect-mi/connect-mi-account.jpeg"
            alt="TV Flow"
          />
        </div>
      </div>
    </div>
  </section>
);

const Learnings = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col col-lg-6">
          <h2 className="mb-2">learnings</h2>
          <p>When building for Shopify, team workflow and project structure are critical! With multiple developers from
            multiple teams contributing hourly, version control and peer review is a must. To accomplish our goals in
            this potentially messy situation, we took a step back – reviewed client pain points, and designed a process
            that aligned all teams and stakeholders in a targeted way with open lines and frequent communication. With
            this fine-tuned workflow, we exercised an “all hands on deck” approach that allowed us to work and
            communicate together efficiently to meet the tight deadline.</p>
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Connect Mi"
      />
      <PortfolioHeader/>
      <PortfolioVideo/>
      <ProjectGoals/>
      <ProductDetailImage/>
      <DevelopmentLanguagesAndNeeds/>
      <ConnectMiTVFlow/>
      <KeyFocusAreas/>
      <ConnectMiAccount/>
      <Learnings/>
      <ProjectForm location={location}/>
      <PortfolioFeed className="my-6"/>
    </Layout>
  );
};

export default Index;
